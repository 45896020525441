type Props = {
    number: number;
    active: boolean;
    onClick?: () => void;
}

const Steps: React.FC<Props> = ({number, active, onClick}) => {
    return (
        <div onClick={onClick} className={(active ? 'bg-purple text-white' : 'text-purple ') + " w-7 h-7 border-2 rounded-lg border-purple inline-block m-1 cursor-pointer transition-all duration-200 hover:bg-purple hover:text-white"}>
            <p className="text-center">{number}</p>
        </div>
    );
}

export default Steps;