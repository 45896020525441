import { MathComponent } from "mathjax-react";

type Props = {
    formula:string;
    bordered?:boolean;
}

const Math: React.FC<Props> = ({formula, bordered = false}) => {
    return (
        <div className={(bordered ? 'bg-slate-50 border-2 border-slate-200 rounded-lg' : '') + " mx-auto w-full overflow-x-auto md:w-fit  px-4 my-4"}>
            <MathComponent tex={formula} />
        </div>
    )
};
export default Math;