import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div className='bg-slate-50 min-h-screen overflow-x-hidden'>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
