import { useState } from "react";
import Math from "./Math";
type Props = {
    data:any;
}

const Subject: React.FC<Props> = ({data}) => {

    const [showSubject, setShowSubject] = useState<boolean>(true);

    return (
        <div>
            <p className="font-bold text-purple mb-2 w-fit cursor-pointer" onClick={() => setShowSubject(!showSubject)}>Sujet <i className={(showSubject ? 'fa-chevron-up' : 'fa-chevron-down') + " fa-solid"}></i></p>
            {showSubject && <div className="rounded-lg border-2 border-slate-200 bg-slate-50 p-4">
                {data.map((subject:any, index:number) => {
                    if(subject.type === "string") return <p key={index}>{subject.text}</p>
                    return <Math formula={subject.text} key={index}/>
                })}
            </div>}
            {!showSubject && <div className="rounded-lg border-2 border-slate-200 bg-slate-50 p-2"><p className="cursor-pointer hover:bg-slate-200 w-fit mx-auto px-4 py-1 rounded-lg" onClick={() => setShowSubject(true)}>...</p></div>}
        </div>
    )
};

export default Subject;