import Math from "./Math"

type Props = {
    data:any;
}

const Textarea: React.FC<Props> = ({data}) => {
    return (
        <div className="w-full">
            {data.map((d: any, index: number) => {
                if(d.type === "string") {
                    return <p key={index}>{d.text}</p>
                }
                if(d.type === "tip"){
                    return (
                        <div key={index} className="border-2 border-purple bg-purple-light p-4 rounded-lg mb-2 relative">
                            <div className="rounded-full bg-purple w-7 h-7 absolute top-[-10px] left-[-10px] text-center"><i className="fa-solid fa-info text-white"></i></div>
                            <p className="text-slate-900">{d.text}</p>
                        </div>
                    )
                }
                return <Math formula={d.text} key={index} />
            })}
        </div>
    )
}

export default Textarea;