import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Example from './pages/Example';
import example_1 from './data/example_1.json';
import example_2 from './data/example_2.json';
import example_3 from './data/example_3.json';
import P404 from './pages/P404';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <P404 />,
    children: [
      {
        path: "/",
        element: <Home/>,
        errorElement: <P404 />,
      },
      {
        path: "/example-1",
        element: <Example data={example_1} />,
        errorElement: <P404 />,
      },
      {
        path: "/example-2",
        element: <Example data={example_2} />,
        errorElement: <P404 />,
      },
      {
        path: "/example-3",
        element: <Example data={example_3} />,
        errorElement: <P404 />,
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RouterProvider router={router} />
);