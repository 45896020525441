type Props = {
    text:string;
}

const Title: React.FC<Props> = ({text}) => {
    return (
        <h1 className="font-bold my-4 text-purple"><i className="fa-solid fa-circle-chevron-right"></i> {text}</h1>
    )
};

export default Title;