import { Link } from "react-router-dom";

type Props = {
    name:string;
    link:string;
    active:boolean;
}
const Navitem: React.FC<Props> = ({name, link, active}) => {
    return (
        <Link to={link} className={(active ? 'bg-purple text-white ' : 'bg-white text-purple ') + "w-full md:mx-2 md:w-fit mx-auto border-2 border-purple inline-block px-8 py-1 rounded-lg my-1 transition-all duration-200 hover:bg-purple hover:text-white"}>
            <p className="text-center font-lato">{name}</p>
        </Link>
    );
}

export default Navitem;