import { useEffect, useState } from "react";
import Subject from "../components/Subjet";
import Steps from "../components/Steps";
import Textarea from "../components/Textarea";

type Props = {
    data: any;
}

const Example: React.FC<Props> = ({data}) => {
    const steps = data.steps;
    const [activeStep, setActiveStep] = useState<number>(0);

    useEffect(() => {
        if(steps[activeStep] === undefined) setActiveStep(0);
    }, [activeStep, steps]);

    return (
        <div className="container mx-auto bg-white border-2 border-slate-200 shadow-sm mt-10 p-4 w-11/12 rounded-lg">
            <Subject data={data.subject} />
            <div className="w-fit mx-auto mt-4">
                {steps.map((_: any, index: number) => {
                    return <Steps active={index === activeStep} number={index+1} key={index} onClick={() => setActiveStep(index)}/>
                })}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                <div className="w-ful">
                    <img src={"/img/charts/example_" + data.example_number + '/' + (activeStep + 1) + '.png'} alt="" className="w-9/12 mx-auto" draggable="false"/>
                </div>
                {steps[activeStep] && <Textarea data={steps[activeStep]} />}
            </div>
        </div>
    );
}

export default Example;