import { useLocation } from "react-router-dom";
import Navitem from "./Navitem";

const Navbar: React.FC = () => {

    const location = useLocation().pathname;

    return (
        <div className="w-11/12 md:w-10/12 mx-auto pt-10">
            <div className="w-fit mx-auto p-2 bg-white border-2 border-slate-200 rounded-lg shadow-sm">
                <Navitem name="Accueil" link="/" active={location === "/"} />
                <Navitem name="Exemple 1" link="/example-1" active={location === "/example-1"} />
                <Navitem name="Exemple 2" link="/example-2" active={location === "/example-2"} />
                <Navitem name="Exemple 3" link="/example-3" active={location === "/example-3"} />
            </div>
        </div>
    )
}

export default Navbar;