import Title from "../components/Title";
import Math from "../components/Math";

const Home: React.FC = () => {
    return (
        <div className="container mx-auto bg-white border-2 border-slate-200 shadow-sm mt-10 p-4 w-11/12 rounded-lg">
            <Title text="Définition :"/>
            <p>Le produit de convolution, noté « * » est un produit qui fait correspondre à deux fonctions f et g la fonction s(t) définie comme suit :</p>
            <Math bordered formula={`s(t) = f(t) * g(t) = \\int_{-\\infty}^{\\infty} f(τ) . g(t-τ) . dτ = \\int_{-\\infty}^{\\infty} g(τ) . f(t-τ) . dτ`} />
            <Title text="Pour calculer un produit de convolution :"/>
            <ul className="list-disc pl-8 mb-4">
                <li>Tout d'abord on trace la courbe de la première fonction en fonction de τ .</li>
                <li>Les fonctions sont les variables de τ et de -τ. Ainsi, il faut renverser l'axe horizontal pour une des deux fonctions et décaler de t.</li>
                <li>Une fois cela fait, nous cherchons à déplacer la seconde courbe sur l'axe des abscisses de -∞ à +∞ pour identifier les différents cas possibles.</li>
                <li>Dans chaque cas, il faut calculer une intégrale telle que définie plus haut.</li>
            </ul>
            <p>Exemple de la convolution d'une porte sur elle même :</p>
            <img src={"/img/Introduction.gif"} alt="" className="mx-auto mt-4" draggable="false"/>
            <p className="text-center text-xs mt-10">Convolution d'une porte par elle même. <a href="https://fr.wikipedia.org/wiki/Produit_de_convolution" target="_blank" className="text-purple underline" rel="noreferrer">Wikipédia</a></p>
        </div>
    )
};

export default Home;